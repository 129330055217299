import React from 'react';

export const SendMail: React.VFC = () => {  
  const mailAddress = 'mugurumakenzai@outlook.jp';
  const mailSubject = '株式会社六車ウェブサイトからのお問い合わせ';
  const startingMailer = () => {
    location.href = `mailto:${mailAddress}?subject=${encodeURI(mailSubject)}`;
  }
  return (
    <>
      <button 
        className='__mail u-flex-center-center u-bold' 
        type='button'
        onClick={startingMailer}
      >メールで問い合わせ</button>
    </>
  )
}