'use strict';

// stylesheet import
// import '../sass/main.sass';
import '../scss/main.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { IntersectionObserverToggleClass } from './modules/class-intersection-observer-toggleclass';
import { IsLegacyIE } from './modules/checking-legacy-ie';
import { FindOS } from './modules/checking-os';
import { FindBrowser } from './modules/checking-browser';
import { CallPhone } from './tsx/app/CallPhone';
import { SendMail } from './tsx/app/SendMail';
import { CloseDrawerMenu } from './tsx/app/CloseDrawerMenu';
import { HeaderEffect } from './tsx/app/HeaderEffect';
import { ImageGallery } from './tsx/app/ImageGallery';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

// React
ReactDOM.render(<CallPhone />, document.getElementById('call_phone') );
ReactDOM.render(<SendMail />, document.getElementById('send_mail') );
ReactDOM.render(<CloseDrawerMenu />, document.getElementById('close_drawer_menu') );
ReactDOM.render(<HeaderEffect />, document.getElementById('header_effect') );
if(document.getElementById('image_gallery') != null) {
  ReactDOM.render(<ImageGallery />, document.getElementById('image_gallery') );
}


// GSAP
gsap.registerPlugin(ScrollTrigger);
const slideInElements = gsap.utils.toArray<HTMLElement>('.js-mask-animation-scroll--element');
const fadeInElements = gsap.utils.toArray<HTMLElement>('.js-fade-animation-scroll--element');
slideInElements.forEach((element) => {
  gsap.fromTo(
    element,
    {
      'clip-path': 'inset(0 100% 0 0)'
    },
    {
      'clip-path': 'inset(0 -100% 0 0)',
      delay: 0.1,
      duration: 3.2,
      ease: 'power1.out',
      scrollTrigger: {
        trigger: element,
        start: 'top 90%',
        end: 'bottom 30%',
        scrub: 2.4
      }
    }
  );
});
fadeInElements.forEach((element) => {
  gsap.fromTo(
    element,
    {
      y: 50,
      autoAlpha: 0
    },
    {
      y: 0,
      autoAlpha: 1,
      delay: 0.1,
      duration: 0.8,
      ease: 'power1.out',
      scrollTrigger: {
        trigger: element,
        start: 'top 90%',
        end: 'bottom 30%',
        scrub: 1.2
      }
    }
  );
});


// Intersection Observer
new IntersectionObserverToggleClass({
  selector: '.observe_target', 
  className: 'observed', 
  toggle: true
});

// checking legacy IE
IsLegacyIE();

// checking OS
FindOS();

// checking browser
FindBrowser();