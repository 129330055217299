import React, { useState, useEffect } from 'react';

export const ImageGallery: React.VFC = () => {  
  const [currentImage, updateImage] = useState(1);
  const change = (index: number) => {
    updateImage(index);
  };
  return (
    <>
      <div className='__block-depth-3 __block-depth-3--1st'>
        <div className='__content'>
          <h2 className='__title'>
            <span className='__en u-capital-case p-home-section-title-en'>products</span>
            <span className='__ja u-inline-block'>
              <span className='u-inline-block'>用途や目的に</span>
              <span className='u-inline-block'>合わせた路盤材と</span>
              <br />
              <span className='u-inline-block'>環境にやさしい</span>
              <span className='u-inline-block'>改良土を取扱い</span>
            </span>
          </h2>
          <p className='__text'>
            建設現場で発生した残土や硬質砂岩と呼ばれる岩石、コンクリート廃材から、粒度調整された砕石や固化剤を混合させた改良土を製造・販売しており、エコやリサイクルの観点から、持続可能な社会を目指し、環境保全に配慮した良質な資材を取り扱っています。
          </p>
        </div>
        <div className='__block-depth-4 u-flex-end-center'>
          <a className='__button u-button-default' href='products.html'>
            <span className='__text'>取扱商材の紹介はこちら</span>
          </a>
        </div>
      </div>
      <div className='__block-depth-3 __block-depth-3--2nd'>
        <div className='__image-wrap'>
          <figure className='__gallery-image c-gallery-image js-gallery-image'>
            { currentImage === 1 && (<img className='__image js-mask-animation-scroll--element' src='assets/images/site/home/home_slide_image_section_03_01_min.jpg' alt='' />) }
            { currentImage === 2 && (<img className='__image js-mask-animation-scroll--element' src='assets/images/site/home/home_slide_image_section_03_02_min.jpg' alt='' />) }
            { currentImage === 3 && (<img className='__image js-mask-animation-scroll--element' src='assets/images/site/home/home_slide_image_section_03_03_min.jpg' alt='' />) }
          </figure>
        </div>
      </div>
      <div className='__block-depth-3 __block-depth-3--3rd u-flex-center-center'>
        <div className='__gallery-thumbnail c-gallery-thumbnail js-gallery-thumbnail u-flex-center-start'>
          <figure className='__thumbnail' onClick={() => change(1)}>
            <img className='__image' src='assets/images/site/home/home_thumbnail_section_03_01_min.jpg' alt='' />
            <figcaption className='u-bold u-align-center __caption'>
              <span className='u-inline-block'>再生砕石</span>
              <span className='u-inline-block'>（RC40）</span>
            </figcaption>
          </figure>
          <figure className='__thumbnail' onClick={() => change(2)}>
            <img className='__image' src='assets/images/site/home/home_thumbnail_section_03_02_min.jpg' alt='' />
            <figcaption className='u-bold u-align-center __caption'>
              <span className='u-inline-block'>粒調砕石</span>
              <span className='u-inline-block'>（M-25）</span>
            </figcaption>
          </figure>
          <figure className='__thumbnail' onClick={() => change(3)}>
            <img className='__image' src='assets/images/site/home/home_thumbnail_section_03_03_min.jpg' alt='' />
            <figcaption className='u-bold u-align-center __caption'>改良土</figcaption>
          </figure>
        </div>
      </div>
    </>
  )
}