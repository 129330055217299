import React, { useState, useEffect } from 'react';
import { OpenDrawerMenu } from './OpenDrawerMenu';

export const HeaderEffect: React.VFC = () => {  
  const [isEffect, updateEffect] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if(300 < scrollY) {
        updateEffect(true);
      } else {
        updateEffect(false);
      }
    })
  }, []);
  return (
    <>
      <section className={isEffect ? 'l-wrapper js-header-add-background' : 'l-wrapper'}>
        <div className='l-container'>
          <div className='l-inner'>
            <div className='__block-depth-1'>
              <div className='__block-depth-2 u-flex-between-center'>
                <div className='__block-depth-3 __block-depth-3--1st'>                  
                  <h1 className='__logo'>
                    <a className='' href='https://muguruma-kenzai.com/'>
                      <img src='assets/images/site/common/common_logo_color_min.svg' alt='株式会社六車' />
                    </a>
                  </h1>
                </div>
                <div className='__block-depth-3 __block-depth-3--2nd'>
                  <div className='__block-depth-4 __block-depth-4--desktop u-flex-between-end u-flex-column'>
                    <div className='__block-depth-5 __block-depth-5--top'>
                      <div className='__contact-info u-flex-center-center'>
                        <span className='__copy u-flex-center-center u-flex-column'>
                          <span className='__main u-bold'>お電話でのお問い合わせ</span>
                          <span className='__sub'>平日9:00〜18:00</span>
                        </span>
                        <span className='__tel u-flex-center-center'>
                          <span className='__icon'>
                            <img className='__image' src='assets/images/site/common/common_icon_tel_color_min.svg' alt='tel' />
                          </span>
                          <span className='__number u-text-en'>072-643-1122</span>
                        </span>
                      </div>
                    </div>
                    <div className='__block-depth-5 __block-depth-5--bottom'>
                      <nav className='c-global-navi __navi'>
                        <ul className='__list u-flex-end-baseline'>
                          <li className='__item'>
                            <a className='__link u-flex-start-baseline' href='service.html'>
                              <span className='__ja u-bold'>業務内容</span>
                              <span className='__en u-text-en u-capital-case'>service</span>
                            </a>
                          </li>
                          <li className='__item'>
                            <a className='__link u-flex-start-baseline' href='products.html'>
                              <span className='__ja u-bold'>取扱商材</span>
                              <span className='__en u-text-en u-capital-case'>products</span>
                            </a>
                          </li>
                          <li className='__item'>
                            <a className='__link u-flex-start-baseline' href='about.html'>
                              <span className='__ja u-bold'>会社概要</span>
                              <span className='__en u-text-en u-capital-case'>about us</span>
                            </a>
                          </li>
                          <li className='__item'>
                            <a className='__link u-flex-start-baseline' href='recruit.html'>
                              <span className='__ja u-bold'>採用案内</span>
                              <span className='__en u-text-en u-capital-case'>recruit</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className='__block-depth-4 __block-depth-4--mobile u-flex-center-end u-flex-column'>
                    <OpenDrawerMenu />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}