import React from 'react';

export const OpenDrawerMenu: React.VFC = () => {
  const open = () => {
    document.querySelector('.js-drawer-menu')?.classList.add('c-drawer-menu--shown');
  }
  return (
    <>
      <button 
        type='button' 
        className='c-hamburger js-hamburger'
        onClick={open}
      >menu</button>
    </>
  )
}