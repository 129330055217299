import React from 'react';

export const CallPhone: React.VFC = () => {  
  const telNo = '072-643-1122';
  const call = () => {
    if (window.confirm(`TEL：${telNo}に発信しますか？`)) {
      location.href = `tel:${telNo.replace(/-/g, '')}`;
    } else {
      window.alert('発信がキャンセルされました');
    }
  }
  return (
    <>
      <button 
        className='__tel u-flex-center-center u-bold' 
        type='button'
        onClick={call}
      >電話で問い合わせ</button>
    </>
  )
}