import React, { useEffect } from 'react';

export const CloseDrawerMenu: React.VFC = () => {
  const close = () => {
    document.querySelector('.js-drawer-menu')?.classList.remove('c-drawer-menu--shown');
  }
  useEffect(() => {
    window.addEventListener('resize', () => {
      if(window.matchMedia('(max-width: 768px)').matches) {
        close();
      }
    });
  }, []);
  return (
    <>
      <button 
        className='__button' 
        type='button'
        onClick={close}
      >閉じる</button>
    </>
  )
}